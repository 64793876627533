body {
  padding: 0px;
  margin: 0px;
  color: #fff;
}

.page-wrapper {
  width: 100%;
  display: flex;
  background: #dbe6f6;
  background: -webkit-linear-gradient(to right, #dbe6f6, #c5796d);
  background: linear-gradient(to right, #dbe6f6, #c5796d);
  min-height: 100vh;
  align-items: center;
}

.bubble {
  display: inline-block;
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bubble:before,
.bubble:after {
  position: absolute;
  content: "";
  border: 3px solid #fff;
  opacity: 0.8;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  /* transform: translate(-50%, -50%); */
  animation: bubble 1.2s cubic-bezier(0.2, 0.8, 0.8, 1) infinite;
}

.bubble:after {
  animation-delay: -0.4s;
}

@keyframes bubble {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
