.lab,
.css-lab {
  margin: 5rem 2rem;
  display: inline-block;
  @media (max-width: 768px) {
    margin: 5rem 0rem;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }
    li {
      display: list-item;
      text-align: -webkit-match-parent;
      height: 220px;
      width: 220px;
      background-color: #f5f5f5;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.4);
      @media (max-width: 500px) {
        height: 130px;
        width: 130px;
        margin: 0 auto;
      }
    }
  }
}
.css-lab ul {
  margin-top: 5rem;
}
.lab ul li:hover,
.css-lab ul li:hover {
  background-color: #606161;
  opacity: 0.4;
}
.lab ul li span,
.css-lab ul li span {
  visibility: hidden;
  position: absolute;
  text-align: left;
  display: inline;
  background: #fff;
  -webkit-box-shadow: -10px 0 0 0 #fff, 10px 0 0 0 #fff;
  -moz-box-shadow: -10px 0 0 0 #fff, 10px 0 0 0 #fff;
  -ms-box-shadow: -10px 0 0 0 #fff, 10px 0 0 0 #fff;
  box-shadow: -10px 0 0 0 #fff, 10px 0 0 0 #fff;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 1.7;
  margin-top: 4rem;
  color: #1e4147;
  font-size: 17px;
}

.lab ul li:hover span,
.css-lab ul li:hover span {
  visibility: visible;
  @media (max-width: 500px) {
    font-size: 14px;
    margin-top: 3rem;
    display: block;
    box-shadow: -3px 0 0 0 #fff, 5px 0 0 0 #fff; 
  }
}

@-webkit-keyframes showcase-pop-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc,
      0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc,
      0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes tshowcase-pop-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc,
      0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc,
      0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

.img-responsive {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.lt {
  width: 150px;
  margin: 1.8rem 0rem;
  @media (max-width: 500px) {
    width: 75px;
    margin: 1.5rem;
  }
}
.gap {
  height: 100px;
  margin: 3.2rem 0rem;
  @media (max-width: 500px) {
    width: 75px;
    margin: 0.7rem 0rem;
  }
}
.amp {
  width: 125px;
  margin: 2.5rem 0rem;
  @media (max-width: 500px) {
    width: 75px;
    margin: 1.5rem;
  }
}
.TB {
  width: 200px;
  margin: 2.5rem 0rem;
  @media(max-width: 500px) {
    margin: 1rem 0rem;
  }
}

.text {
  position: absolute;
  text-align: left;
  h4,
  p {
    margin: 0px;
  }
}

//*LendingTree Page **/
.tabs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 5rem 2rem;
  grid-gap: 2rem;
}
.tab-list {
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  font-size: 32px;
  text-transform: uppercase;
}

.tab-list-active {
  border-bottom: 1px solid black;
  font-weight: bold;
}
.tab-content .img-responsive {
  width: 500px;
}
.about-project {
  text-align: left;
  ul li {
    padding-bottom: 1rem;
  }
}

.keyboard {
  width: 100px;
  margin: 3rem 0rem;
  @media (max-width: 500px) {
    margin: 0rem;
  }
}
// custom css lab styles 
.lab h1 {
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 2rem;
  text-align: center;
}

.lab-css {
  width: 175px;
  margin: 1.3rem 0rem;
  @media(max-width: 500px) {
    width: 100px;
    margin: 1rem 0rem;
  }
}