//colors
$teal: #408588;
$text: rgba(12, 12, 10, 0.8);
$page-margin: 5vw;

header.aboutMeee {
  min-height: 100vh;
  padding: 5rem $page-margin 2rem;
  overflow: hidden;
  h1 {
    font-size: calc(1.3rem + 1.8vw);
    margin: 0;
    line-height: 1.2em;
  }
}

@supports (display: grid) {
  header.aboutMeee {
    display: flex;
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 12rem;
    color: #333;
    .header-wrapper {
      width: 53vw;
      display: grid;
      margin: auto;
      grid-template-columns: auto 1fr 1fr 1fr 0.5fr;
      grid-template-rows: auto 1fr 1fr auto auto;
      grid-gap: 1rem;
      transform: rotate(-35deg);
    }
    h1:nth-child(1) {
      grid-column: 1 / 4;
      text-align: right;
    }
    h1:nth-child(2) {
      color: $teal;
      writing-mode: vertical-lr;
      grid-column: 3 / 4;
      grid-row: 2 / 5;
      justify-self: end;
      margin-top: -10px;
      margin-right: -10px;
    }

    .my-image {
      grid-column: 1 / 3;
      grid-row: 4 / span 1;
      font-weight: 700;
      line-height: 1.2;
      align-self: end;
      @media(max-width: 500px) {
        grid-row: 3 / span 1;
      }
    }
    .background-content {
      grid-column: 3 / 6;
      grid-row: 5;
      font-size: 0.9rem;
      border-bottom: 25px solid $teal;
      align-self: start;
      justify-content: end;
      line-height: 1.3;
      padding-bottom: 5px;
      font-weight: 400;
      text-align: left;
    }
    .education {
      border-top: 5px solid $text;
      border-bottom: 5px solid $text;
      font-weight: 700;
      grid-row: 3;
      grid-column: 4 / 6;
      align-self: end;
      text-align: left;
    }

    @media (max-width: 820px) {
      display: flex;
      margin: auto;
      padding-top: 5rem;
      padding-bottom: 5rem;
      .header-wrapper {
        transform: rotate(0);
      }
      h1:nth-child(2) {
        margin-top: -5px;
      }
      .education {
        margin-left: 1rem;
        align-self: start;
      }
      .background-content {
        border-bottom: 20px solid $teal;
      }
    }
    @media (max-width: 700px) {
      .demo-list {
        grid-column: 3 / -1;
        grid-row: 6;
      }
    }
    @media (max-width: 500px) {
      padding-top: 2rem;
      .header-wrapper {
        width: 100%;
        grid-template-columns: auto 1fr 1fr 1fr 0.5fr;
        grid-template-rows: auto auto auto auto;
      }
      h1:nth-child(1) {
        grid-column: 1 / -1;
      }
      h1:nth-child(2) {
        grid-column: 5;
        grid-row: 2 / 4;
        margin-right: -5px;
      }
      .follow-links {
        grid-column: 1 / 4;
        grid-row: 3;
        border-bottom: 10px solid $teal;
      }
      .demo-list {
        grid-column: 1 / -1;
        grid-row: 4;
      }
      .education {
        grid-column: 1 / -1;
        grid-row: 2;
        margin-left: 0;
        width: 85%;
        text-align: left;
      }
      .background-content {
        grid-column: 1 / 6;
      }
    }
    @media (max-width: 480px) {
      header.aboutMeee .background-content {
        grid-row: 2;
        grid-column: 0;
      }
    }
    @media (min-width: 1000px) {
      padding-bottom: 15rem;
    }
    @media (min-width: 1300px) {
      padding-bottom: 25rem;
    }
    @media (min-width: 1800px) {
      padding-bottom: 30rem;
    }
  }
}

.image {
  background-image: url("../pictures/me1.JPG");
  background-repeat: no-repeat;
  width: 400px;
  height: 400px;
  background-size: cover;
  grid-column: 1;
  border: 1rem solid #e9ceb1;
  @media (max-width: 500px) {
    width: 240px;
  }
}
p.rae {
  text-align: left;
  margin-top: 5px;
  font-size: 10px;
  color: #539db3;
  font-weight: 300;
  a {
    color: #1e4147;
    text-decoration: underline;
  }
  @media (max-width: 480px) {
    width: 200px;
  }
}
/** image animationn **/
.image-pan {
  -webkit-animation: image-pan 10s both;
  animation: image-pan 10s both;
}
@-webkit-keyframes image-pan {
  0% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 100% 0%;
  }
}
@keyframes image-pan {
  0% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 100% 0%;
  }
}
.reference {
  text-align: right;
  font-size: 10px;
  color: #e9ceb1;
  opacity: 0.1;
}
