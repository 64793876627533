@import url("https://fonts.googleapis.com/css?family=Londrina+Shadow");
@import url("https://fonts.googleapis.com/css?family=Karla");

//** all styles **
* {
  box-sizing: border-box;
  color: #333;
}
html {
  font-family: "Karla", sans-serif;
  line-height: 1.3em;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  text-size-adjust: 100%;
  color: #333;
}
body {
  text-align: center;
  margin: 0px;
  color: #333;
}
h1 {
  line-height: 1.2;
}
a {
  color: black;
  text-decoration: none;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
header.nav-bar {
  padding: 2rem;
  text-transform: uppercase;
  border-bottom: 1px solid black;
  @media (max-width: 500px) {
    justify-content: center;
  }
  h1 {
    margin: 0 0 0.5em;
    font-size: 32px;
    @media (max-width: 500px) {
      font-size: 24px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
  @media (min-width: 501px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    h1 {
      margin: 0 1em 0 0;
    }
  }

  nav ul {
    display: flex;
    margin-bottom: 4px;
    justify-content: space-between;
    font-size: 20px;
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
  @media (min-width: 300px) {
    nav li {
      flex: auto;
      margin-right: 1em;
      padding-right: 1em;
      border-right: 1px solid #000;
    }
  }
  @media (min-width: 580px) {
    nav ul {
      flex-wrap: wrap;
    }
  }
  nav li:last-child {
    border: none;
    margin-right: 0;
    padding-right: 0;
  }
}

//* its me section **/
.hero {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 0px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
  color: #333;
  h1 {
    text-transform: uppercase;
    grid-row: 2 / 3;
    font-size: calc(2.2rem + 3vw);
    margin-left: 1rem;
    margin-right: 1rem;
    @media (max-width: 500px) {
      margin: 0rem 2rem;
    }
  }
  h2 {
    text-transform: uppercase;
    border: 1px solid black;
    background: rgba(255, 255, 255, 0.6);
    padding: 1rem 0.5rem 0.6rem;
    font-size: 1.2rem;
    margin: 0;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    align-self: self-start;
    @media (max-width: 880px) {
      grid-column: 2 / 4;
    }
  }
}
/* its me jessica section **/
.hero {
  font-family: "Londrina Shadow", cursive;
}
.hero h1 {
  display: grid;
  justify-content: start;
}
h1 {
  font-size: 8rem;
  text-transform: uppercase;
  line-height: 1em;
  margin: 0px;
  text-align: left;
}

.hero h1 span {
  display: block;
}
.hero h1 span:nth-child(1) {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}
.hero h1 span:nth-child(2) {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 45%;
  margin: 0 -22px;
  padding-top: 3px;
  letter-spacing: -0.3em;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}
.hero h1 span:nth-child(3) {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  padding-left: 10px;
}

.sub-title {
  font-family: "Karla", sans-serif;
  text-align: left;
  text-transform: uppercase;
  font-size: 16px;
  grid-row: 3 / 3;
  @media (max-width: 500px) {
    margin: 1rem 2rem 0rem 2rem;
    font-size: 12px;
  }
}

//*bounce in animation
.bounce {
  -webkit-animation: bounce 2.5s both;
  animation: bounce 2.5s both;
}
@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
