@import url(https://fonts.googleapis.com/css?family=Londrina+Shadow);
@import url(https://fonts.googleapis.com/css?family=Karla);
* {
  box-sizing: border-box;
  color: #333; }

html {
  font-family: "Karla", sans-serif;
  line-height: 1.3em;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  color: #333; }

body {
  text-align: center;
  margin: 0px;
  color: #333; }

h1 {
  line-height: 1.2; }

a {
  color: black;
  text-decoration: none; }

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0; }

header.nav-bar {
  padding: 2rem;
  text-transform: uppercase;
  border-bottom: 1px solid black; }
  @media (max-width: 500px) {
    header.nav-bar {
      justify-content: center; } }
  header.nav-bar h1 {
    margin: 0 0 0.5em;
    font-size: 32px; }
    @media (max-width: 500px) {
      header.nav-bar h1 {
        font-size: 24px; } }
    @media (max-width: 480px) {
      header.nav-bar h1 {
        font-size: 16px; } }
  @media (min-width: 501px) {
    header.nav-bar {
      display: flex;
      justify-content: space-between;
      align-items: flex-end; }
      header.nav-bar h1 {
        margin: 0 1em 0 0; } }
  header.nav-bar nav ul {
    display: flex;
    margin-bottom: 4px;
    justify-content: space-between;
    font-size: 20px; }
    @media (max-width: 480px) {
      header.nav-bar nav ul {
        font-size: 12px; } }
  @media (min-width: 300px) {
    header.nav-bar nav li {
      flex: auto;
      margin-right: 1em;
      padding-right: 1em;
      border-right: 1px solid #000; } }
  @media (min-width: 580px) {
    header.nav-bar nav ul {
      flex-wrap: wrap; } }
  header.nav-bar nav li:last-child {
    border: none;
    margin-right: 0;
    padding-right: 0; }

.hero {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 0px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
  color: #333; }
  .hero h1 {
    text-transform: uppercase;
    grid-row: 2 / 3;
    font-size: calc(2.2rem + 3vw);
    margin-left: 1rem;
    margin-right: 1rem; }
    @media (max-width: 500px) {
      .hero h1 {
        margin: 0rem 2rem; } }
  .hero h2 {
    text-transform: uppercase;
    border: 1px solid black;
    background: rgba(255, 255, 255, 0.6);
    padding: 1rem 0.5rem 0.6rem;
    font-size: 1.2rem;
    margin: 0;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    align-self: self-start; }
    @media (max-width: 880px) {
      .hero h2 {
        grid-column: 2 / 4; } }

/* its me jessica section **/
.hero {
  font-family: "Londrina Shadow", cursive; }

.hero h1 {
  display: grid;
  justify-content: start; }

h1 {
  font-size: 8rem;
  text-transform: uppercase;
  line-height: 1em;
  margin: 0px;
  text-align: left; }

.hero h1 span {
  display: block; }

.hero h1 span:nth-child(1) {
  grid-column: 1 / 3;
  grid-row: 1 / 2; }

.hero h1 span:nth-child(2) {
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  -webkit-text-orientation: upright;
          text-orientation: upright;
  font-size: 45%;
  margin: 0 -22px;
  padding-top: 3px;
  letter-spacing: -0.3em;
  grid-column: 1 / 2;
  grid-row: 2 / 3; }

.hero h1 span:nth-child(3) {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  padding-left: 10px; }

.sub-title {
  font-family: "Karla", sans-serif;
  text-align: left;
  text-transform: uppercase;
  font-size: 16px;
  grid-row: 3 / 3; }
  @media (max-width: 500px) {
    .sub-title {
      margin: 1rem 2rem 0rem 2rem;
      font-size: 12px; } }

.bounce {
  -webkit-animation: bounce 2.5s both;
  animation: bounce 2.5s both; }

@-webkit-keyframes bounce {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1; }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

@keyframes bounce {
  0% {
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  38% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1; }
  55% {
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  72% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  81% {
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  90% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  95% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

.lab,
.css-lab {
  margin: 5rem 2rem;
  display: inline-block; }
  @media (max-width: 768px) {
    .lab,
    .css-lab {
      margin: 5rem 0rem; } }
  .lab ul,
  .css-lab ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem; }
    @media (max-width: 768px) {
      .lab ul,
      .css-lab ul {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem; } }
    .lab ul li,
    .css-lab ul li {
      display: list-item;
      text-align: -webkit-match-parent;
      height: 220px;
      width: 220px;
      background-color: #f5f5f5;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.4); }
      @media (max-width: 500px) {
        .lab ul li,
        .css-lab ul li {
          height: 130px;
          width: 130px;
          margin: 0 auto; } }

.css-lab ul {
  margin-top: 5rem; }

.lab ul li:hover,
.css-lab ul li:hover {
  background-color: #606161;
  opacity: 0.4; }

.lab ul li span,
.css-lab ul li span {
  visibility: hidden;
  position: absolute;
  text-align: left;
  display: inline;
  background: #fff;
  -ms-box-shadow: -10px 0 0 0 #fff, 10px 0 0 0 #fff;
  box-shadow: -10px 0 0 0 #fff, 10px 0 0 0 #fff;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 1.7;
  margin-top: 4rem;
  color: #1e4147;
  font-size: 17px; }

.lab ul li:hover span,
.css-lab ul li:hover span {
  visibility: visible; }
  @media (max-width: 500px) {
    .lab ul li:hover span,
    .css-lab ul li:hover span {
      font-size: 14px;
      margin-top: 3rem;
      display: block;
      box-shadow: -3px 0 0 0 #fff, 5px 0 0 0 #fff; } }

@-webkit-keyframes showcase-pop-up {
  0% {
    transform: translateY(0);
    transform-origin: 50% 50%;
    text-shadow: none; }
  100% {
    transform: translateY(-20px);
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3); } }

@-webkit-keyframes tshowcase-pop-up {
  0% {
    transform: translateY(0);
    transform-origin: 50% 50%;
    text-shadow: none; }
  100% {
    transform: translateY(-20px);
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3); } }

@keyframes tshowcase-pop-up {
  0% {
    transform: translateY(0);
    transform-origin: 50% 50%;
    text-shadow: none; }
  100% {
    transform: translateY(-20px);
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3); } }

.img-responsive {
  display: inline-block;
  max-width: 100%;
  height: auto; }

.lt {
  width: 150px;
  margin: 1.8rem 0rem; }
  @media (max-width: 500px) {
    .lt {
      width: 75px;
      margin: 1.5rem; } }

.gap {
  height: 100px;
  margin: 3.2rem 0rem; }
  @media (max-width: 500px) {
    .gap {
      width: 75px;
      margin: 0.7rem 0rem; } }

.amp {
  width: 125px;
  margin: 2.5rem 0rem; }
  @media (max-width: 500px) {
    .amp {
      width: 75px;
      margin: 1.5rem; } }

.TB {
  width: 200px;
  margin: 2.5rem 0rem; }
  @media (max-width: 500px) {
    .TB {
      margin: 1rem 0rem; } }

.text {
  position: absolute;
  text-align: left; }
  .text h4,
  .text p {
    margin: 0px; }

.tabs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 5rem 2rem;
  grid-gap: 2rem; }

.tab-list {
  padding-left: 0; }

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  font-size: 32px;
  text-transform: uppercase; }

.tab-list-active {
  border-bottom: 1px solid black;
  font-weight: bold; }

.tab-content .img-responsive {
  width: 500px; }

.about-project {
  text-align: left; }
  .about-project ul li {
    padding-bottom: 1rem; }

.keyboard {
  width: 100px;
  margin: 3rem 0rem; }
  @media (max-width: 500px) {
    .keyboard {
      margin: 0rem; } }

.lab h1 {
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 2rem;
  text-align: center; }

.lab-css {
  width: 175px;
  margin: 1.3rem 0rem; }
  @media (max-width: 500px) {
    .lab-css {
      width: 100px;
      margin: 1rem 0rem; } }

header.aboutMeee {
  min-height: 100vh;
  padding: 5rem 5vw 2rem;
  overflow: hidden; }
  header.aboutMeee h1 {
    font-size: calc(1.3rem + 1.8vw);
    margin: 0;
    line-height: 1.2em; }

@supports (display: grid) {
  header.aboutMeee {
    display: flex;
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 12rem;
    color: #333; }
    header.aboutMeee .header-wrapper {
      width: 53vw;
      display: grid;
      margin: auto;
      grid-template-columns: auto 1fr 1fr 1fr 0.5fr;
      grid-template-rows: auto 1fr 1fr auto auto;
      grid-gap: 1rem;
      transform: rotate(-35deg); }
    header.aboutMeee h1:nth-child(1) {
      grid-column: 1 / 4;
      text-align: right; }
    header.aboutMeee h1:nth-child(2) {
      color: #408588;
      -webkit-writing-mode: vertical-lr;
              writing-mode: vertical-lr;
      grid-column: 3 / 4;
      grid-row: 2 / 5;
      justify-self: end;
      margin-top: -10px;
      margin-right: -10px; }
    header.aboutMeee .my-image {
      grid-column: 1 / 3;
      grid-row: 4 / span 1;
      font-weight: 700;
      line-height: 1.2;
      align-self: end; }
      @media (max-width: 500px) {
        header.aboutMeee .my-image {
          grid-row: 3 / span 1; } }
    header.aboutMeee .background-content {
      grid-column: 3 / 6;
      grid-row: 5;
      font-size: 0.9rem;
      border-bottom: 25px solid #408588;
      align-self: start;
      justify-content: end;
      line-height: 1.3;
      padding-bottom: 5px;
      font-weight: 400;
      text-align: left; }
    header.aboutMeee .education {
      border-top: 5px solid rgba(12, 12, 10, 0.8);
      border-bottom: 5px solid rgba(12, 12, 10, 0.8);
      font-weight: 700;
      grid-row: 3;
      grid-column: 4 / 6;
      align-self: end;
      text-align: left; }
    @media (max-width: 820px) {
      header.aboutMeee {
        display: flex;
        margin: auto;
        padding-top: 5rem;
        padding-bottom: 5rem; }
        header.aboutMeee .header-wrapper {
          transform: rotate(0); }
        header.aboutMeee h1:nth-child(2) {
          margin-top: -5px; }
        header.aboutMeee .education {
          margin-left: 1rem;
          align-self: start; }
        header.aboutMeee .background-content {
          border-bottom: 20px solid #408588; } }
    @media (max-width: 700px) {
      header.aboutMeee .demo-list {
        grid-column: 3 / -1;
        grid-row: 6; } }
    @media (max-width: 500px) {
      header.aboutMeee {
        padding-top: 2rem; }
        header.aboutMeee .header-wrapper {
          width: 100%;
          grid-template-columns: auto 1fr 1fr 1fr 0.5fr;
          grid-template-rows: auto auto auto auto; }
        header.aboutMeee h1:nth-child(1) {
          grid-column: 1 / -1; }
        header.aboutMeee h1:nth-child(2) {
          grid-column: 5;
          grid-row: 2 / 4;
          margin-right: -5px; }
        header.aboutMeee .follow-links {
          grid-column: 1 / 4;
          grid-row: 3;
          border-bottom: 10px solid #408588; }
        header.aboutMeee .demo-list {
          grid-column: 1 / -1;
          grid-row: 4; }
        header.aboutMeee .education {
          grid-column: 1 / -1;
          grid-row: 2;
          margin-left: 0;
          width: 85%;
          text-align: left; }
        header.aboutMeee .background-content {
          grid-column: 1 / 6; } }
    @media (max-width: 480px) {
      header.aboutMeee header.aboutMeee .background-content {
        grid-row: 2;
        grid-column: 0; } }
    @media (min-width: 1000px) {
      header.aboutMeee {
        padding-bottom: 15rem; } }
    @media (min-width: 1300px) {
      header.aboutMeee {
        padding-bottom: 25rem; } }
    @media (min-width: 1800px) {
      header.aboutMeee {
        padding-bottom: 30rem; } } }

.image {
  background-image: url(/static/media/me1.07e7342b.JPG);
  background-repeat: no-repeat;
  width: 400px;
  height: 400px;
  background-size: cover;
  grid-column: 1;
  border: 1rem solid #e9ceb1; }
  @media (max-width: 500px) {
    .image {
      width: 240px; } }

p.rae {
  text-align: left;
  margin-top: 5px;
  font-size: 10px;
  color: #539db3;
  font-weight: 300; }
  p.rae a {
    color: #1e4147;
    text-decoration: underline; }
  @media (max-width: 480px) {
    p.rae {
      width: 200px; } }

/** image animationn **/
.image-pan {
  -webkit-animation: image-pan 10s both;
  animation: image-pan 10s both; }

@-webkit-keyframes image-pan {
  0% {
    background-position: 0% 100%; }
  100% {
    background-position: 100% 0%; } }

@keyframes image-pan {
  0% {
    background-position: 0% 100%; }
  100% {
    background-position: 100% 0%; } }

.reference {
  text-align: right;
  font-size: 10px;
  color: #e9ceb1;
  opacity: 0.1; }


.contact main {
  width: 70%;
  margin: 0 auto; }
  @media (min-width: 1441px) {
    .contact main {
      width: 50%;
      margin: 0 auto; } }
  @media (max-width: 767px) {
    .contact main {
      width: 80%; } }

.contact h1 {
  font-family: 'Sanchez Slab W03', Courier, serif;
  font-weight: normal;
  transform: rotate(-45deg);
  float: left;
  -webkit-shape-outside: polygon(nonzero, -58px 80.7%, 76.05% -70px, 94.55% -23.3%, -26.05% 200%);
  shape-outside: polygon(nonzero, -58px 80.7%, 76.05% -70px, 94.55% -23.3%, -26.05% 200%);
  padding-bottom: 50px;
  text-transform: uppercase;
  font-size: 2em; }
  @media (max-width: 767px) {
    .contact h1 {
      padding-bottom: 100px;
      font-size: 3vh;
      -webkit-shape-outside: polygon(nonzero, -58px 80.7%, 76.05% -70px, 94.55% -23.3%, -26.05% 92%);
      shape-outside: polygon(nonzero, -58px 80.7%, 76.05% -70px, 94.55% -23.3%, -26.05% 92%); } }

.contact section {
  margin: 100px 0; }
  @media (max-width: 767px) {
    .contact section {
      margin: 50px 0px; } }

.contact p, .contact a {
  text-align: left; }
  @media (max-width: 767px) {
    .contact p, .contact a {
      margin: 0px;
      font-size: 12px; } }

body {
  padding: 0px;
  margin: 0px;
  color: #fff;
}

.page-wrapper {
  width: 100%;
  display: flex;
  background: #dbe6f6;
  background: linear-gradient(to right, #dbe6f6, #c5796d);
  min-height: 100vh;
  align-items: center;
}

.bubble {
  display: inline-block;
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bubble:before,
.bubble:after {
  position: absolute;
  content: "";
  border: 3px solid #fff;
  opacity: 0.8;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  /* transform: translate(-50%, -50%); */
  -webkit-animation: bubble 1.2s cubic-bezier(0.2, 0.8, 0.8, 1) infinite;
          animation: bubble 1.2s cubic-bezier(0.2, 0.8, 0.8, 1) infinite;
}

.bubble:after {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

@-webkit-keyframes bubble {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

@keyframes bubble {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.parent {
  background: linear-gradient(-45deg, #ddd6f3, #e6cac9, #ee7752, #e73c7e);
  background-size: 400% 400%;
  -webkit-animation: GradientBackground 15s ease infinite;
  animation: GradientBackground 15s ease infinite;
  overflow: hidden;
}
@-webkit-keyframes GradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes GradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.heart-container {
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 99vh;
  background-color: #fff;
}
.heart-shape {
  position: relative;
  width: 200px;
  height: 200px;
  background: #ff0099;
  background: linear-gradient(to bottom, #493240, #ff0099);
  transform: rotate(45deg);
  -webkit-animation: heartbeat 2.5s infinite;
          animation: heartbeat 2.5s infinite;
}

.heart-shape:before {
  position: absolute;
  bottom: 0px;
  left: -100px;
  width: 200px;
  height: 200px;
  content: "";
  border-radius: 50%;
  background: #ff0099;
  background: linear-gradient(to bottom, #493240, #ff0099);
}
.heart-shape:after {
  position: absolute;
  top: -100px;
  right: 0px;
  width: 200px;
  height: 200px;
  content: "";
  border-radius: 50%;
  background: #ff0099;
  background: linear-gradient(to bottom, #493240 50%, #ff0099 148%);
}

.line-1,
.line-2,
.line-3 {
  position: absolute;
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  width: 300px;
  border-color: #fff;
  transform: rotate(-45deg);
}
.line-1 {
  top: 96%;
}
.line-2 {
  top: 76%;
}

.line-3 {
  top: 56%;
}

@-webkit-keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

div#reload {
  position: fixed;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #03001e;
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e);
  font-size: 25px;
  font-weight: bold;
  color: white;
  box-shadow: 0px 0px 5px 1px black;
  cursor: pointer; }

#outie {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 2px #a39d9d;
  -webkit-animation: opac 4s ease-in-out 1;
          animation: opac 4s ease-in-out 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#innie {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: #03001e;
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e); }

#uno {
  position: absolute;
  top: 66.4%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 230px; }

#uno-dos {
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 230px; }

#dos {
  position: absolute;
  top: 73.7%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 198px; }

#dos-dos {
  position: absolute;
  top: 76%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 198px; }

#tres {
  position: absolute;
  top: 81.3%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 175px; }

#tres-dos {
  position: absolute;
  top: 84%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 175px; }

#ur-mom {
  position: absolute;
  top: 89.2%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  z-index: 4;
  height: 0;
  width: 128px; }

#ur-mom-dos {
  position: absolute;
  top: 93%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 128px; }

div#text-logo {
  position: fixed;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vmax;
  background-clip: text;
  background: #03001e;
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bolder;
  -webkit-animation: opac 4s ease-in-out 1;
          animation: opac 4s ease-in-out 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes opac {
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes opac {
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@media only screen and (max-height: 566px) {
  div#text-logo {
    top: 90%;
    font-size: 1rem; }
  div#text2 {
    font-size: 1rem; } }

@media only screen and (max-height: 344px) {
  div#text-logo {
    top: 94%; } }

@media only screen and (max-height: 305px) {
  div#text-logo {
    display: none; } }

#phase-1 {
  width: 100vw;
  height: 38vh;
  position: relative;
  overflow: hidden; }

#phase-2 {
  width: 100vw;
  height: 50vh;
  position: relative;
  overflow: hidden; }

#first-semi {
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  border-radius: 50%;
  width: 1px;
  background-color: #7303c0;
  -webkit-animation: enl 4s 3s ease 1;
          animation: enl 4s 3s ease 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#second-semi {
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  border-radius: 50%;
  width: 1px;
  background-color: #7303c0;
  -webkit-animation: enl 5s 3.5s ease 1;
          animation: enl 5s 3.5s ease 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

#third-semi {
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  border-radius: 50%;
  width: 1px;
  background-color: #7303c0;
  -webkit-animation: enl 6s 3.8s ease 1;
          animation: enl 6s 3.8s ease 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes enl {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.3; }
  99% {
    height: 100vmax;
    width: 120vmax;
    bottom: -50vmax; }
  100% {
    opacity: 0; } }

@keyframes enl {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.3; }
  99% {
    height: 100vmax;
    width: 120vmax;
    bottom: -50vmax; }
  100% {
    opacity: 0; } }

div#half-logo1 {
  position: absolute;
  top: 100vmax;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: #03001e;
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e);
  -webkit-animation: up 4s 3s cubic-bezier(1, -0.22, 0.36, 0.99) 1;
          animation: up 4s 3s cubic-bezier(1, -0.22, 0.36, 0.99) 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes up {
  100% {
    top: 100%;
    opacity: 1; } }

@keyframes up {
  100% {
    top: 100%;
    opacity: 1; } }

div#logo2-outer {
  position: absolute;
  top: -180%;
  left: 50%;
  transform: translate(-50.11%, -50%);
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: #03001e;
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e);
  -webkit-animation: down 4s 3s cubic-bezier(1, -0.22, 0.36, 0.99) 1;
          animation: down 4s 3s cubic-bezier(1, -0.22, 0.36, 0.99) 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes down {
  100% {
    top: 0%; } }

@keyframes down {
  100% {
    top: 0%; } }

/* #outer2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
  opacity: 0;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 2px rgb(163, 157, 157);
  animation: appr 2s ease 7s 1;
  animation-fill-mode: forwards;
} */
@-webkit-keyframes appr {
  100% {
    opacity: 1; } }
@keyframes appr {
  100% {
    opacity: 1; } }

#text2 {
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vmax;
  background-clip: text;
  background: #03001e;
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bolder;
  -webkit-animation: text 2s cubic-bezier(1, -0.22, 0.36, 0.99) 7s 1;
          animation: text 2s cubic-bezier(1, -0.22, 0.36, 0.99) 7s 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0; }

@-webkit-keyframes text {
  100% {
    opacity: 1;
    top: 75%;
    opacity: 1; } }

@keyframes text {
  100% {
    opacity: 1;
    top: 75%;
    opacity: 1; } }

@media only screen and (max-height: 566px) {
  div#text2 {
    font-size: 1rem; }
  @-webkit-keyframes text {
    100% {
      top: 85%;
      opacity: 1; } }
  @keyframes text {
    100% {
      top: 85%;
      opacity: 1; } } }

@media only screen and (max-height: 301px) {
  div#text2 {
    display: none; } }

.meltaway {
	background: #1d1f20;
	height: 100vh;
}

#position {
	width: 400px;
	height: 400px;
	position: relative;
	margin: auto;
	transform: rotateZ(15deg)
}

.surrounding-ring {
	position: relative;
	width: 100%;
	height: 100%;
	background: -webkit-radial-gradient( center, ellipse cover, rgba(24, 19, 25, 0) 0%, rgba(248, 144, 9, 0) 34%, rgba(248, 144, 9, 0) 36%, rgba(248, 144, 9, 0) 37%, rgba(248, 144, 9, 0) 38%, rgba(248, 144, 9, 0) 39%, rgba(248, 144, 9, 0) 40%, rgba(248, 144, 9, 0) 41%, rgba(248, 144, 9, 0) 42%, rgba(248, 144, 9, 0) 44%, rgba(248, 144, 9, 0) 46%, rgba(248, 144, 9, 0) 48%, rgba(248, 144, 9, 0) 49%, rgba(248, 144, 9, 0) 51%, rgba(248, 144, 9, 0) 52%, rgba(33, 213, 177, .3) 53%, rgba(33, 213, 177, .6) 54%, rgba(33, 213, 177, 1) 55%, rgba(33, 213, 177, .8) 56%, rgba(33, 213, 177, .6) 58%, rgba(33, 213, 177, .4) 60%, rgba(33, 213, 177, .2) 61%, rgba(33, 213, 177, .1) 62%, rgba(248, 144, 9, 0) 63%, #192322 65%, rgba(33, 213, 177, .5) 67%, rgba(33, 213, 177, .8) 68%, rgba(30, 213, 177, 1) 69%, rgba(171, 240, 225, .4) 70%, rgba(78, 73, 76, 0) 71%, rgba(77, 72, 76, 0) 72%, rgba(248, 144, 9, 0) 100%);
	transform: rotateX(75deg);
}

.circle {
	width: 180px;
	height: 180px;
	position: absolute;
	top: 110px;
	left: 110px;
	border-radius: 50%;
}


/* this allows the front of the ring to display in the front */

.top {
	clip: rect(0px, 180px, 90px, 0px);
}


/*this positions the top and bottom portion of the circle to be viewed upright */

.circle.bottom.planet-bg,
.circle.top.planet-bg {
	transform: rotateZ(-15deg)
}

.planet-bg {

	background: -webkit-radial-gradient( top, circle cover, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 1) 100%), -webkit-linear-gradient(bottom, hsl(198, 68%, 66%) 0%, hsl(198, 68%, 66%) 3%, hsl(198, 68%, 66%) 5%, hsl(198, 68%, 66%) 9%, hsl(198, 68%, 66%) 20%, hsl(198, 68%, 66%) 32%, hsl(339, 98%, 55%) 45%, hsl(339, 98%, 55%) 47%, hsl(325, 95%, 55%) 48%, hsl(330, 95%, 55%) 49%, hsl(335, 95%, 55%) 51%, hsl(339, 98%, 55%) 59%, hsl(34, 95%, 55%) 61%, hsl(31, 95%, 55%) 63%, hsl(31, 95%, 55%) 64%, hsl(25, 95%, 55%) 68%, hsl(31, 95%, 55%) 74%, hsl(50, 93%, 55%) 78%, hsl(45, 93%, 55%) 83%, hsl(40, 93%, 55%) 85%, hsl(45, 93%, 55%) 90%, hsl(50, 93%, 55%) 100%);
	
};

.line1,
.line2,
.line3 {
	position: absolute;
	border-style: solid;
	border-width: 8px 2px 0px 2px;
	width: 300px;
	border-color: #1d1f20;
}

.line1 {
	top: 20%;
}

.line2 {
	top: 40%;
}

.line3 {
	top: 60%;
}

.inspiration {
	width: 50%;
	margin: 0 auto;
	color: #fff !important;
	text-align: left;
}
@media(max-width: 500px) {
	.inspiration {
		width: 90%;
	}
}
