
div#reload {
  position: fixed;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #03001e;
  background: -webkit-linear-gradient(
    to top,
    #fdeff9,
    #ec38bc,
    #7303c0,
    #03001e
  );
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e);
  font-size: 25px;
  font-weight: bold;
  color: white;
  box-shadow: 0px 0px 5px 1px black;
  cursor: pointer;
}
#outie {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 2px rgb(163, 157, 157);
  animation: opac 4s ease-in-out 1;
  animation-fill-mode: forwards;
}

#innie {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: #03001e;
  background: -webkit-linear-gradient(
    to top,
    #fdeff9,
    #ec38bc,
    #7303c0,
    #03001e
  );
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e);
}

#uno {
  position: absolute;
  top: 66.4%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 230px;
}
#uno-dos {
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 230px;
}

#dos {
  position: absolute;
  top: 73.7%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 198px;
}
#dos-dos {
  position: absolute;
  top: 76%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 198px;
}

#tres {
  position: absolute;
  top: 81.3%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 175px;
}
#tres-dos {
  position: absolute;
  top: 84%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 175px;
}
#ur-mom {
  position: absolute;
  top: 89.2%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  z-index: 4;
  height: 0;
  width: 128px;
}
#ur-mom-dos {
  position: absolute;
  top: 93%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 8px 2px 0px 2px;
  border-color: white transparent transparent transparent;
  height: 0;
  width: 128px;
}

div#text-logo {
  position: fixed;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vmax;
  background-clip: text;
  background: #03001e;
  background: -webkit-linear-gradient(
    to top,
    #fdeff9,
    #ec38bc,
    #7303c0,
    #03001e
  );
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bolder;
  animation: opac 4s ease-in-out 1;
  animation-fill-mode: forwards;
}
@keyframes opac {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media only screen and (max-height: 566px) {
  div#text-logo {
    top: 90%;
    font-size: 1rem;
  }
  div#text2 {
    font-size: 1rem;
  }
}
@media only screen and (max-height: 344px) {
  div#text-logo {
    top: 94%;
  }
}
@media only screen and (max-height: 305px) {
  div#text-logo {
    display: none;
  }
}
#phase-1 {
  width: 100vw;
  height: 38vh;
  position: relative;
  overflow: hidden;
}
#phase-2 {
  width: 100vw;
  height: 50vh;
  position: relative;
  overflow: hidden;
}
#first-semi {
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  border-radius: 50%;
  width: 1px;
  background-color: #7303c0;
  animation: enl 4s 3s ease 1;
  animation-fill-mode: forwards;
}

#second-semi {
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  border-radius: 50%;
  width: 1px;
  background-color: #7303c0;
  animation: enl 5s 3.5s ease 1;
  animation-fill-mode: forwards;
}
#third-semi {
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  border-radius: 50%;
  width: 1px;
  background-color: #7303c0;
  animation: enl 6s 3.8s ease 1;
  animation-fill-mode: forwards;
}
@keyframes enl {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  99% {
    height: 100vmax;
    width: 120vmax;
    bottom: -50vmax;
  }
  100% {
    opacity: 0;
  }
}
div#half-logo1 {
  position: absolute;
  top: 100vmax;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: #03001e;
  background: -webkit-linear-gradient(
    to top,
    #fdeff9,
    #ec38bc,
    #7303c0,
    #03001e
  );
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e);
  animation: up 4s 3s cubic-bezier(1, -0.22, 0.36, 0.99) 1;
  animation-fill-mode: forwards;
}
@keyframes up {
  100% {
    top: 100%;
    opacity: 1;
  }
}
div#logo2-outer {
  position: absolute;
  top: -180%;
  left: 50%;
  transform: translate(-50.11%, -50%);
  width: 230px;
  height: 230px;
  border-radius: 50%;
  background: #03001e;
  background: -webkit-linear-gradient(
    to top,
    #fdeff9,
    #ec38bc,
    #7303c0,
    #03001e
  );
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e);
  animation: down 4s 3s cubic-bezier(1, -0.22, 0.36, 0.99) 1;
  animation-fill-mode: forwards;
}
@keyframes down {
  100% {
    top: 0%;
  }
}
/* #outer2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
  opacity: 0;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 2px rgb(163, 157, 157);
  animation: appr 2s ease 7s 1;
  animation-fill-mode: forwards;
} */
@keyframes appr {
  100% {
    opacity: 1;
  }
}
#text2 {
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vmax;
  background-clip: text;
  background: #03001e;
  background: -webkit-linear-gradient(
    to top,
    #fdeff9,
    #ec38bc,
    #7303c0,
    #03001e
  );
  background: linear-gradient(to top, #fdeff9, #ec38bc, #7303c0, #03001e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bolder;
  animation: text 2s cubic-bezier(1, -0.22, 0.36, 0.99) 7s 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes text {
  100% {
    opacity: 1;
    top: 75%;
    opacity: 1;
  }
}
@media only screen and (max-height: 566px) {
  div#text2 {
    font-size: 1rem;
  }
  @keyframes text {
    100% {
      top: 85%;
      opacity: 1;
    }
  }
}
@media only screen and (max-height: 301px) {
  div#text2 {
    display: none;
  }
}
